      
      function changeCountry(value) {
        $('#Form .country-select').val(value).trigger('change');
      }

      
      function storeCountry(country) {
        $.ajax({
          type: "POST",
          url: "/?cmd=set-country",
          dataType: "json",
          data: {
            country: country
          },
          success: function(data) {
            window.location.reload();
          },
          error: function(data) {
            window.location.reload();
          }
        });
      }
      
      function initUIElements() {
        $('input[type="checkbox"].invalid').each(function() {
          $(this).closest('.form-group').addClass('invalid');
        });
        
        $('input.flat').iCheck({
          checkboxClass: 'icheckbox_minimal-blue',
          radioClass: 'iradio_minimal-blue',
          increaseArea: '20%' // optional
        });
      
        $('.slim-scroll').each(function() {
          var container = $(this).closest('.slim-scroll-container');
          $(this).slimScroll({
            height: container.height() - container.css('margin-top').replace("px", "")
          });
        });
        
        $('.country-sites-menu-container').each(function() {
          $(this).slimScroll({ height: 'auto' });
        });
        
        $('.gutter.gutter-horizontal').on("click", function () {
          if ($('.country-sites-menu-navbar').hasClass('closed')) {
            $('.country-sites-menu-navbar').removeClass('closed');
          }
          else {
            $('.country-sites-menu-navbar').addClass('closed');
          }
        });
        
        $("button[name='update-all-sites']").on("click", function () {
          $("#confirmCountryChange .newslettersDaily").iCheck($("#confirmCountryChange input[name='allSitesNewslettersDaily']")["0"].checked? "check" : "uncheck");
          $("#confirmCountryChange .newslettersWeekly").iCheck($("#confirmCountryChange input[name='allSitesNewslettersWeekly']")["0"].checked? "check" : "uncheck");
          $("#confirmCountryChange .specialOffers").iCheck($("#confirmCountryChange input[name='allSitesSpecialOffers']")["0"].checked? "check" : "uncheck");
          $("#confirmCountryChange .personalizeArticles").iCheck($("#confirmCountryChange input[name='allSitesPersonalizeArticles']")["0"].checked? "check" : "uncheck");
          $('form.dirty').removeClass('dirty');
          $('#confirmCountryChange form').addClass('updated');
        });
        
        $('.subscription-option').on('ifToggled', function() {
          $('#confirmCountryChange form').addClass('dirty');
        });
        
        $('a').on("click", function (e) {
          $('#confirmCountryChange form').removeClass('updated');
        });
        
        $('#confirmCountryChange form').areYouSure();
        
        $('.nav-tabs a, .country-sites-menu-container a').on("click", function(){
          $(this).tab('show');
        })
      }
      
      var gdprCountries = [
        "Austria", "Belgium", "Bulgaria", "Croatia", "Cyprus", "Czech Republic", "Denmark", "Estonia", "Finland", "France", 
        "Germany", "Greece", "Hungary", "Ireland", "Italy", "Latvia", "Lithuania", "Luxembourg", "Malta",
        "Netherlands", "Poland", "Portugal", "Romania", "Slovakia", "Slovenia", "Spain", "Sweden", "United Kingdom",
        "Canada",
        "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", 
        "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT",
        "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "UK",
        "CA"];

      $("button.cancel-subscriptions-submit").on("click", function() {
        $("#confirmCountryChange form #consentOptions button[name='change-country-submit']" ).trigger("click");
      });
      
      $("button.cancel-subscriptions-cancel").on("click", function() {
        $("#cancel-subscriptions-modal").modal('hide');
      });
      
      $("#termsAndConditionsContinue").on("click", function () {
        if (document.getElementById('acceptTermsAndConditions').checked) {
          if ($("#confirmCountryChange input[name='formMode']").val() == "full") {
            $("#youMustChooseOneOption").hide();
            
            $("#confirmCountryChange #singleSitePanel").hide();
            $("#confirmCountryChange #acceptTermsPanel").hide();
            $("#confirmCountryChange #consentOptions").show();
            $("#confirmCountryChange #updateAllSitesPanel").tab('show');
          }
          else {
            $("#confirmCountryChange form #consentOptions button[name='change-country-submit']").trigger("click");
          }
        }
        else if (document.getElementById('cancelCountryChange').checked) {
          $("#youMustChooseOneOption").hide();
          $("#confirmCountryChange form button[name='change-country-cancel']").trigger("click");
        }
        else if (document.getElementById('cancelAllSubscriptions').checked) {
          $("#youMustChooseOneOption").hide();
          $("#cancel-subscriptions-modal").modal('show');
        }
        else {
          $("#youMustChooseOneOption").show();
        }
      });
      
      $(function() {
        // Populate select from JSON request to make initial page load faster.
        $.getJSON("/user/countries", function(data) {
          var items = "";
          $.each(data, function(key, val) {
            items += "<option value='" + val.name + "' data-alternative-spellings='" + val.code + " " + val.spelling + "' data-relevancy-booster='" + val.relevance + "'>" + val.name + "</option>";
          });
          $(items).appendTo("select.country-select");
          $('select.country-select').selectToAutocomplete();
          if(typeof settedUserCountry != 'undefined' && settedUserCountry) {
            changeCountry(settedUserCountry);
          }
        });

        $('.select-country-reload select.country-select').on("change", function(){
          var country = $(this).val();
          if (country != '') {
            storeCountry(country);
          }
          return false;
        });
        
        $('#fullCompleteRegistrationForm select.country-select').on("change", function(){
          var form = $('#fullCompleteRegistrationForm');
          var country = $(this).val();
          if (country != '' && gdprCountries.includes(country)) {
            $("#confirmCountryChange input[name='formMode']").val("full");
            
            $("#confirmCountryChange input[name='multipleSubscriptions']").val("false");
            
            $("#confirmCountryChange input[name='country']").val($(this).val());
            
            var newslettersDaily = form.find("input[name='newslettersDaily']");  
            $("#confirmCountryChange input[name='newslettersDaily']").iCheck((newslettersDaily.length > 0 && newslettersDaily["0"].checked)? "check" : "uncheck");
            
            var newslettersWeekly = form.find("input[name='newslettersWeekly']");
            $("#confirmCountryChange input[name='newslettersWeekly']").iCheck((newslettersWeekly.length > 0 && newslettersWeekly["0"].checked)? "check" : "uncheck");
            
            var specialOffers = form.find("input[name='specialOffers']");
            $("#confirmCountryChange input[name='specialOffers']").iCheck((specialOffers.length > 0 && specialOffers["0"].checked)? "check" : "uncheck");
            
            var personalizeArticles = form.find("input[name='personalizeArticles']");
            $("#confirmCountryChange input[name='personalizeArticles']").iCheck((personalizeArticles.length > 0 && personalizeArticles["0"].checked)? "check" : "uncheck");
            
            var termsAndConditions = form.find("input[name='termsAndConditions']");
            $("#confirmCountryChange input[type='checkbox'][name='termsAndConditions']").iCheck((termsAndConditions.length > 0 && termsAndConditions["0"].checked)? "check" : "uncheck");
            
            $("#confirmCountryChange input[name='newslettersDaily']").trigger("focus");
            $('#confirmCountryChange').modal('show');
          }
          return false;
        });
        
        $('#shortCompleteRegistrationForm select.country-select').on("change", function(){
          var form = $('#shortCompleteRegistrationForm');
          var country = $(this).val();
          if (country != '' && gdprCountries.includes(country)) {
            $("#confirmCountryChange input[name='formMode']").val("full");
            
            $("#confirmCountryChange .terms-and-conditions-only").hide();
            $("#confirmCountryChange .subscription-options").show();
            
            $("#confirmCountryChange #singleSitePanel").hide();
            $("#confirmCountryChange #acceptTermsPanel").show();
            $("#confirmCountryChange #consentOptions").hide();
            $("#confirmCountryChange #updateAllSitesPanel").tab('show');
            
            $("#confirmCountryChange input[name='multipleSubscriptions']").val("true");
            
            $("#confirmCountryChange input[name='country']").val($(this).val());
            
            $("#confirmCountryChange #acceptTermsAndConditions").iCheck("uncheck");
            $("#confirmCountryChange #cancelCountryChange").iCheck("uncheck");
            $("#confirmCountryChange #cancelAllSubscriptions").iCheck("uncheck");
            
            var newslettersDaily = form.find("input[name='newslettersDaily']");
            if (newslettersDaily.length > 0 && newslettersDaily["0"].checked) {
              $("#confirmCountryChange input[name='allSitesNewslettersDaily']").iCheck("check");
              $("#confirmCountryChange input[name='newslettersDaily-" + bgId + "']").iCheck("check");
            }
            var newslettersWeekly = form.find("input[name='newslettersWeekly']");
            if(newslettersWeekly.length > 0 && newslettersWeekly["0"].checked) {
              $("#confirmCountryChange input[name='allSitesNewslettersWeekly']").iCheck("check");
              $("#confirmCountryChange input[name='newslettersWeekly-" + bgId + "']").iCheck("check");
            }
            var specialOffers = form.find("input[name='specialOffers']");
            if(specialOffers.length > 0 && specialOffers["0"].checked) {
              $("#confirmCountryChange input[name='allSitesSpecialOffers']").iCheck("check");
              $("#confirmCountryChange input[name='specialOffers-" + bgId + "']").iCheck("check");
            }
            var personalizeArticles = form.find("input[name='personalizeArticles']");
            if(personalizeArticles.length > 0 && personalizeArticles["0"].checked) {
              $("#confirmCountryChange input[name='allSitesPersonalizeArticles']").iCheck("check");
              $("#confirmCountryChange input[name='personalizeArticles-" + bgId + "']").iCheck("check");
            }
            
            $("#confirmCountryChange #acceptTermsAndConditions").trigger("focus");
            $('#confirmCountryChange').modal('show');
          }
          return false;
        });
        
        $(".gdpr-show").closest('.form-group').show();
        $(".gdpr-hide").closest('.form-group').hide();
        
        if ($("input[name='consent-daily']").is(':visible') 
            || $("input[name='consent-wmy']").is(':visible')
            || $("input[name='consent-specialoffers']").is(':visible')) 
        {
          $(".consentEmailLabel").show();
        }
        else {
          $(".consentEmailLabel").hide();
        }      
        
        $('#Form select.country-select').on("change", function() {
          var country = $(this).val();
          if (country != '' && typeof gdprCountries !== 'undefined') {
            if (gdprCountries.includes(country)) {
              $("input[name='consent-sponsor']").closest('.form-group').show();
              $("input[name='consent-terms']").closest('.form-group').show();
              $("input[name='consent-personalize']").closest('.form-group').show();
              $("input[name='consent-daily']").closest('.form-group').show();
              $("input[name='consent-wmy']").closest('.form-group').show();
              $("input[name='consent-specialoffers']").closest('.form-group').show();
              $(".consentEmailLabel").show();

              $("input[name='consent-sponsor']:not('.consented')").iCheck("uncheck");
              $("input[name='consent-terms']:not('.consented')").iCheck("uncheck");
              $("input[name='consent-personalize']:not('.consented')").iCheck("uncheck");
              $("input[name='consent-daily']:not('.consented')").iCheck("uncheck");
              $("input[name='consent-wmy']:not('.consented')").iCheck("uncheck");
              $("input[name='consent-specialoffers']:not('.consented')").iCheck("uncheck");
            }
            else {
              $("input[name='consent-sponsor']").closest('.form-group').show();
              $("input[name='consent-terms']").closest('.form-group').show();
              $("input[name='consent-personalize']").closest('.form-group').show();
              $("input[name='consent-specialoffers']").closest('.form-group').show();
            }
          }
        });
        if (initUIElements) {
          initUIElements();
        }
      });
      
      $('.cookie-notification .close').on("click", function() {
        storeCountry('United States');
      });