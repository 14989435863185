var aggregage = aggregage || {};

function initializeSelectInputFields() 
{
  if(!jQuery().select2) {
    document.write('<script src="{{context.jsPath}}select2.js"></script>');
  }
  
  $("select").css("width", "100%");
  $("select.invalid").parent().addClass('input-error');
  var $select2optional = $("select:not(.required):not(.country-select)").select2({
    minimumResultsForSearch: Infinity,
    width: 'resolve'
  });
  var $select2required = $("select.required:not(.country-select)").select2({
    minimumResultsForSearch: Infinity,
    width: 'resolve'
  });
  $select2required.each(function() {
    $(this).data('select2').$container.addClass("required");
  });
}

$(function() {

  //Declare the callback for OAuth login success.
  aggregage.oauth = aggregage.oauth || {};
  aggregage.oauth.loginSuccess = function(childWindow, newUser, redirect, oAuthApplicationId, accountSwitched, messages,oAuthType,baseUrl,bg) 
  {
  childWindow.close();
    if (redirect) {
      if (window.location.href.endsWith(redirect)) {
        window.location.reload(true);
      }
      else {
        window.location.replace(redirect);
      }
    }
    else if (accountSwitched) {
      window.location.reload(true);
    }
    else 
    {
       if(oAuthType == 'LinkedIn')
       {
         var parentNode = document.getElementById('linkedInNode').childNodes[0];
         var child1 = $('#linkedInId1');
         var aElement = document.createElement('a');
         aElement.setAttribute("href", "#");
         aElement.setAttribute("type", "button");
         aElement.id="linkedInId2";
         aElement.setAttribute("id", "linkedInId2");
         aElement.innerHTML ="Disconnect from Linkedin";
         aElement.setAttribute("data-url", baseUrl+"disconnectLinkedIn");
         aElement.setAttribute("data-base-url", baseUrl);
         aElement.setAttribute("name","clear-filter");
         aElement.setAttribute("data-oapp-id", oAuthApplicationId);
         aElement.setAttribute("data-bg-id", bg);
         aElement.classList.add('btn');
         aElement.classList.add('flat');
         aElement.classList.add('btn-lg');
         aElement.classList.add('btn-default');
         aElement.onclick= function() {disconnectLinkedInButton()};
         var item = document.getElementById("linkedInNode");
         item.replaceChild(aElement, item.childNodes[1]);
      }
      else 
      {
        var parentNode = document.getElementById('twitterNode').childNodes[0];
        var child1 = $('#twitterId1');
        var aElement = document.createElement('a');
          aElement.setAttribute("href", "#");
          aElement.setAttribute("type", "button");
          aElement.id="twitterId2";
          aElement.setAttribute("id", "twitterId2");
          aElement.innerHTML ="Disconnect from Twitter";
          aElement.setAttribute("data-url", baseUrl+"disconnectTwitter");
          aElement.setAttribute("data-base-url", baseUrl);
          aElement.setAttribute("name","clear-filter");
          aElement.setAttribute("data-oapp-id", oAuthApplicationId);
          aElement.setAttribute("data-bg-id", bg);
          aElement.classList.add('btn');
          aElement.classList.add('flat');
          aElement.classList.add('btn-lg');
          aElement.classList.add('btn-default');
          aElement.onclick= function() {disconnectTwitterButton()};
          var item = document.getElementById("twitterNode");
          item.replaceChild(aElement, item.childNodes[1]);
      }
    }
    if (messages && messages.length > 0) {
      messages.forEach(function(message) {
        $('.messages').append(message);
      });
      $('.messages').show();
    }
  }
  
  $(".oAuthLogin").on("click", function(e) {
  e.preventDefault();
    var $this = $(this);
    $('.messages').empty();
    window.open(loginUrl
            + "&oa=" + $this.data("oapp-id")
            + "&bg=" + $this.data("bg-id")
            + "&new=" + $this.hasClass("newAccount")
            +"&baseUrl="+ $this.data("base-url"),
            "OAuthLogin",
            "location=1,status=1,scrollbars=1,width=500,height=500");
  });
  
  initializeKeywordButtons = function() 
  {
    $(".keyword .btn").on("click", function() {
      
      var $this = $(this),
          $form = $this.closest('form'),
          url = $form.data("toggle-keyword-url"),
          bloggroupId = $form.data("bg-id"),
          keywordId = $this.data("keyword-id"),
          isSelected = $this.hasClass("selected"),
          currentKeyword = $form.find('#current-keyword-' + keywordId),
          categoryKeyword = $('[data-keyword-id=' + keywordId + ']');
      
      currentKeyword.toggleClass('selected');
      currentKeyword.find('i.fa').toggleClass('fa-heart');
      currentKeyword.find('i.fa').toggleClass('fa-heart-o');
      
      categoryKeyword.toggleClass('selected');
      categoryKeyword.find('i.fa').toggleClass('fa-heart');
      categoryKeyword.find('i.fa').toggleClass('fa-heart-o');
      
      if (!isSelected) {
        currentKeyword.closest('span').removeClass('hidden');
      }
      
      $.ajax({
        type: "POST",
        url: url,
        dataType: "json",
        data: {
          blogGroupID: bloggroupId,
          keywordId: keywordId,
          isSelected: isSelected
        },
        success: function(data) {
          if (data.response.isSelected) {
            currentKeyword.addClass('selected');
            currentKeyword.find('i.fa').removeClass('fa-heart-o');
            currentKeyword.find('i.fa').addClass('fa-heart');
            currentKeyword.parent().removeClass('hidden');
           
            categoryKeyword.addClass('selected');
            categoryKeyword.find('i.fa').removeClass('fa-heart-o');
            categoryKeyword.find('i.fa').addClass('fa-heart');
          }
          else {
            currentKeyword.removeClass('selected');
            currentKeyword.find('i.fa').removeClass('fa-heart');
            currentKeyword.find('i.fa').addClass('fa-heart-o');
            
            categoryKeyword.removeClass('selected');
            categoryKeyword.find('i.fa').removeClass('fa-heart');
            categoryKeyword.find('i.fa').addClass('fa-heart-o');
          }
        },
        error: function(data) {
          if (data.status == 401) {
            window.location.replace('/');
          }
          else {
            currentKeyword.toggleClass('selected');
            currentKeyword.find('i.fa').toggleClass('fa-heart');
            currentKeyword.find('i.fa').toggleClass('fa-heart-o');
            
            categoryKeyword.toggleClass('selected');
            categoryKeyword.find('i.fa').toggleClass('fa-heart');
            categoryKeyword.find('i.fa').toggleClass('fa-heart-o');
          }
        }
      });
    });
  };
  initializeKeywordButtons();

  $('.subscription').on('ifToggled', function() {
    var $this = $(this),
        $form = $this.closest('form'),
        validation = $form.find(".validation").empty().hide(),
        url = $this.data("toggle-subscription-url"),
        bloggroupId = $this.data("bg-id"),
        subscriptionType = $this.data("type"),
        isChecked = $this.is(":checked");
  
    $.ajax({
      type: "POST",
      url: url,
      dataType: "json",
      data: {
        subscriptionType: subscriptionType,
        checked: isChecked,
        blogGroupID: bloggroupId
      },
      success: function(data) {
        if (data && data.response 
            && data.response.status == 'termsAndConditionsConsentPopUp' 
            && typeof openTermsAndConditionsPopUp !== 'undefined') 
        {
          openTermsAndConditionsPopUp(data.response.country, data.response.multipleSubscriptions, data.response.siteList);
        }
        if (data && data.message 
                && data.status == 'fail') 
        {
          validation
            .html($("<p></p>").html(data.message))
            .show();
        }
        if (data && data.response)
        {
          validation
            .html($("<p></p>").html(data.response.message))
            .show();
        }
      },
      error: function(data) {
      if (data.status == 401) {
          window.location.replace('/');
        }
        else if (data.message) {
          validation
              .html($("<p></p>").html(data.message))
              .show();
        }
        else {
          validation
          .html($("<p></p>").html('Unable to process the request'))
          .show();
        }
      }
    });
  });
  
  function checkTestEmailStatus(url, form, count) {
    var loading = form.find(".loading-indicator"),
        validation = form.find(".validation").empty().hide(),
        testEmailSent = $('.testEmailSent');
    $.ajax({
      type: "GET",
      url: url + "Status",
      dataType: "json",
      success: function(data) {
        if (data.status == 'success')
        {
          testEmailSent.addClass('hidden');
          if (data.message) {
            testEmailSent.removeClass('hidden');
            testEmailSent
                .html($("<p></p>").html('<i class="fa fa-check text-info"></i> ' + data.message))
                .show();
          }
          if (loading) {
            loading.hide();
          }
        }
        else if (data.status == 'fail')
        {
          if (count > 0) {
            setTimeout(function() {
              checkTestEmailStatus(url, form, count-1);
            }, 1000);
          }
          else {
            testEmailSent.addClass('hidden');
            if (data.message) {
              validation
                  .html($("<p></p>").html(data.message))
                  .show();
            }
            if (loading) {
              loading.hide();
            }
          }
        }
      },
      error: function(data) {
        testEmailSent.addClass('hidden');
        if (data.status == 401) {
          window.location.replace('/');
        }
        else { 
          if (loading) {
            loading.hide();
          }
          validation
              .html($("<p></p>").html("Unable to send test email."))
              .show();
        }
      }
    });
  }
 function oAuthLoginLinkedInJs() 
 {
   var $this = $(this);
   var $child1 = $('#linkedInId1');
   $('.messages').empty();
     window.open(loginUrl
       + "&oa=" + $child1.data("oapp-id")
       + "&bg=" + $child1.data("bg-id")
       + "&new=" + $child1.hasClass("newAccount")
       +"&baseUrl="+ $child1.data("base-url"),
       "OAuthLogin",
       "location=1,status=1,scrollbars=1,width=500,height=500");
   }
   
   function oAuthLoginTwitterJs() 
   {
      var $this = $(this);
      var $child1 = $('#twitterId1');
      $('.messages').empty();
         window.open(loginUrl
          + "&oa=" + $child1.data("oapp-id")
          + "&bg=" + $child1.data("bg-id")
          + "&new=" + $child1.hasClass("newAccount")
          +"&baseUrl="+ $child1.data("base-url"),
          "OAuthLogin",
          "location=1,status=1,scrollbars=1,width=500,height=500");
   }
   
   function disconnectLinkedInButton ()
   {
     var $child1 = $('#linkedInId2');
     var item = document.getElementById("linkedInNode");
     var baseUrl =  $child1.attr('data-base-url');
     var $this = $(this),
     $form = $this.closest('form'),
     url = $child1.attr('data-url'),
     appId= $child1.attr('data-oapp-id'),
     bloggroupId = $child1.attr('data-bg-id');
     var item = document.getElementById("linkedInNode");
     $.ajax({
       type: "POST",
       url: url,
       dataType: "json",
       timeout: 60000,
       data: {
         blogGroupID: bloggroupId
      },
      success: function(data) {
        if (data.status == 'success')
        {
            var aElement = document.createElement('a');
            aElement.setAttribute("href", "#");
            aElement.setAttribute("type", "button");
            aElement.id="linkedInId1";
            aElement.setAttribute("id", "linkedInId1");
            aElement.innerHTML ="Connect to Linkedin";
            aElement.setAttribute("data-base-url", baseUrl);
            aElement.setAttribute("data-oapp-id", appId);
            aElement.setAttribute("data-bg-id", bloggroupId);
            aElement.classList.add('btn');
            aElement.classList.add('flat');
            aElement.classList.add('btn-lg');
            aElement.classList.add('btn-info');
            aElement.onclick= function() {oAuthLoginLinkedInJs()};
            aElement.setAttribute("type", 'button');
            var item = document.getElementById("linkedInNode");
            item.replaceChild(aElement, item.childNodes[1]);
        }
        else if (data.status == 'fail')
        {
          if (data.message) {
            $(".validation-linkedin")
                .html($("<p></p>").html(data.message))
                .show();
          }
       }
     },
     error: function(data) {
        if (data.status == 401) {
          window.location.replace('/');
        }
        else {
          $(".validation-linkedin")
            .html($("<p></p>").html("Unable to disconnect."))
            .show();
        }
     }
   });
 }
 
 function disconnectTwitterButton () 
 {
    var $child1 = $('#twitterId2');
     var item = document.getElementById("twitterNode");
     var baseUrl =  $child1.attr('data-base-url');
     var $this = $(this),
     $form = $this.closest('form'),
     url = $child1.attr('data-url'),
     appId= $child1.attr('data-oapp-id'),
     bloggroupId = $child1.attr('data-bg-id');
     var item = document.getElementById("twitterNode");
     $.ajax({
      type: "POST",
      url: url,
      dataType: "json",
      timeout: 60000,
      data: {
        blogGroupID: bloggroupId
      },
      success: function(data) {
        if (data.status == 'success')
        {
           var aElement = document.createElement('a');
           aElement.setAttribute("href", "#");
           aElement.setAttribute("type", "button");
           aElement.id="twitterId1";
           aElement.setAttribute("id", "twitterId1");
           aElement.innerHTML ="Connect to Twitter";
           aElement.setAttribute("data-base-url", baseUrl);
           aElement.setAttribute("data-oapp-id", appId);
           aElement.setAttribute("data-bg-id", bloggroupId);
           aElement.classList.add('btn');
           aElement.classList.add('flat');
           aElement.classList.add('btn-lg');
           aElement.classList.add('btn-info');
           aElement.onclick= function() {oAuthLoginTwitterJs()};
           aElement.setAttribute("type", 'button');
           var item = document.getElementById("twitterNode");
           item.replaceChild(aElement, item.childNodes[1]);
        }
        else if (data.status == 'fail')
        {
          if (data.message) {
             $(".validation-twitter")
                .html($("<p></p>").html(data.message))
                .show();
          }
        }
     },
     error: function(data) {
       if (data.status == 401) {
         window.location.replace('/');
       }
       else {
         $(".validation-twitter")
            .html($("<p></p>").html("Unable to disconnect."))
            .show();
       }
     }
   });
 }
  
  $(".sendTestEmailButton").on("click", function() {
    var $this = $(this),
        $form = $this.closest('form'),
        url = $this.data("url"),
        bloggroupId = $form.data("bg-id"),
        loading = $form.find(".loading-indicator"),
        validation = $form.find(".validation").empty().hide(),
        testEmailSent = $('.testEmailSent');
        testEmailSent.removeClass('hidden');
        testEmailSent
          .html($("<p></p>").html('Please wait, sending test...'))
          .show();
    if (loading) {
      loading.show();
    }
    $.ajax({
      type: "POST",
      url: url,
      dataType: "json",
      timeout: 60000,
      data: {
        blogGroupID: bloggroupId
      },
      success: function(data) {
        if (data.status == 'success')
        {
          setTimeout(function() {
            checkTestEmailStatus(url, $form, 90);
          }, 1000);
        }
        else if (data.status == 'fail')
        {
          testEmailSent.addClass('hidden');
          if (data.message) {
            validation
                .html($("<p></p>").html(data.message))
                .show();
          }
          if (loading) {
            loading.hide();
          }
        }
      },
      error: function(data) {
        testEmailSent.addClass('hidden');
        if (data.status == 401) {
          window.location.replace('/');
        }
        else { 
          if (loading) {
            loading.hide();
          }
          validation
              .html($("<p></p>").html("Unable to send test email."))
              .show();
        }
      }
    });
  });
  
  $(".ajax-link").on("click", function() {
    var $this = $(this),
        $form = $this.closest('form'),
        url = $this.data("url"),
        confirmationText = $this.data("confirm"),
        bloggroupId = $form.data("bg-id"),
        loading = $this.find(".loading-indicator"),
        validation = $form.find(".validation").empty().hide();
    
    if (confirmationText == undefined || !confirm(confirmationText)) {
      return;
    }
    if (loading) {
      loading.show();
    }
    $.ajax({
      type: "POST",
      url: url,
      dataType: "json",
      timeout: 60000,
      data: {
        blogGroupID: bloggroupId
      },
      success: function(data) {
        if (data.redirect) {
          window.location.replace(data.redirect);
        } 
        else if (data.reload) {
          window.location.reload(true);
        }
         
        if (data.message) {
          validation
            .html($("<p></p>").html(data.message))
            .show();
        }
        
        if (loading) {
          loading.hide();
        }
      },
      error: function(data) {
        if (data.status == 401) {
          window.location.replace('/');
        }
        else { 
          if (loading) {
            loading.hide();
          }
          validation
              .html($("<p></p>").html("Unknown error."))
              .show();
        }
      }
    });
  });
  
  $(".sendVerificationEmail").on("click", function() {
    var $this = $(this),
        $form = $this.closest('form'),
        url = $this.data("url"),
        bloggroupId = $form.data("bg-id"),
        loading = $form.find(".loading-indicator"),
        validation = $form.find(".validation").empty().hide(),
        verificationEmailSent = $('.verificationEmailSent');
    verificationEmailSent.addClass('hidden');
    if (loading) {
      loading.show();
    }
    $.ajax({
      type: "POST",
      url: url,
      dataType: "json",
      data: {
        blogGroupID: bloggroupId
      },
      success: function(data) {
        if (data.status == 'success')
        {
          if (data.message) {
            verificationEmailSent.removeClass('hidden');
            verificationEmailSent
                .html(data.message)
                .show();
          }
        }
        else if (data.status == 'fail')
        {
          if (data.message) {
            validation
                .html($("<p></p>").html(data.message))
                .show();
          }
        }
        if (loading) {
          loading.hide();
        }
      },
      error: function(data) {
        if (data.status == 401) {
          window.location.replace('/');
        }
        else { 
          if (loading) {
            loading.hide();
          }
          validation
              .html($("<p></p>").html("Unable to send verification email."))
              .show();
        }
      }
    });
  });
  
  $('.expandable button.expand').on("click", function() {
    var detailsPanel = $(this).closest(".expandable");
    detailsPanel.removeClass('compact').addClass('expanded');
  }); 
  
});




// Recaptcha Variables
var recaptchaForgotVersion = "V3";
var widgetId1 = null;

function snakeToCamel(str) {
  return str.replace(/([-_]\w)/g, function(g) {return g[1].toUpperCase();});
}

function showStateField(newCountry) {
  if (newCountry === "US" || newCountry === "United States") {
    $('.state-block').removeClass('hidden').addClass('hidden-ro-form-field').show();
    $('.province-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
    $('.region-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
    
    $("input[name='userPostalCode']").prop('placeholder', 'ZIP Code');
  }
  else if (newCountry === "CA" || newCountry === "Canada") {
    $('.state-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
    $('.province-block').removeClass('hidden').addClass('hidden-ro-form-field').show();
    $('.region-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
    
    $("input[name='userPostalCode']").prop('placeholder', 'Postal Code');
  }
  else {
    $('.state-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
    $('.province-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
    $('.region-block').removeClass('hidden').addClass('hidden-ro-form-field').show();
    
    $("input[name='userPostalCode']").prop('placeholder', 'ZIP/Postal Code');
  }
}

$(function() {

  $(".sign-up-btn-number").slabText({"fontRatio" : 0.9});
  
  var MODAL_WIDTH = 556,
      LOGIN_HEIGHT = 525,
      REGISTRATION_HEIGHT = 662,
      FORGOT_PASS_HEIGHT = 340,
      overlayClose,
      loginForm = $("#newLoginForm"),
      registrationForm = $("#newRegistrationForm"),
      forgotPasswordForm = $("#newForgotPasswordForm"),
      fullCompleteRegistrationForm = $("#fullCompleteRegistrationForm"),
      shortCompleteRegistrationForm = $("#shortCompleteRegistrationForm"),
      accountBlockedModal = $("#account-blocked-modal"),
      validation = $(".validation"),
      captcha = $("#recaptcha_widget_div"),
      captchaValidation = $(".captchaValidation");
  
  // Activate Ajax forms 
  var ajaxWizardFormSubmitOptions = { 
      dataType: 'json', 
      beforeSubmit: beforeSubmitJsonWizardForm,  // pre-submit callback
      success: processJsonWizardForm,           // post-submit callback 

      // other available options: 
      //target:        '#output1',   // target element(s) to be updated with server response 
      //url:       url         // override for form's 'action' attribute 
      //type:      type        // 'get' or 'post', override for form's 'method' attribute 
      //dataType:  null        // 'xml', 'script', or 'json' (expected server response type) 
      //clearForm: true        // clear all form fields after successful submit 
      //resetForm: true        // reset the form after successful submit 

      // $.ajax options can be used here too, for example: 
      //timeout:   3000 
  };

  function beforeSubmitJsonWizardForm(data, $form, options) 
  {
    var loading = $form.find(".loading-indicator"),
        validation = $form.find(".validation").empty().hide();
    
    if (loading) {
      loading.show();
    }
    
    $('p.input-error').remove();
    $('.input-error').removeClass('input-error');
    
    return true;
  }
  
  function processJsonWizardForm(data, statusText, xhr, $form) 
  {
    var inputs = $form.find("input"),
        loading = $form.find(".loading-indicator"),
        validation = $form.find(".validation").empty().hide();

    if (data.status == 'success')
    {
      // Update form fields
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          var val = data[key];
          $form.find("[name='" + key + "']").val(val);
        }
      }
      
      if (data.userCountry) {
        // Cancel changing country 
        $(".country-select").val(data.userCountry);
        showStateField(data.userCountry);
//        if (gdprCountries !== undefined && gdprCountries.includes(data.userCountry)) {
//          $(".gdpr-only").show();
//        }
//        else {
//          $(".gdpr-only").hide();
//        }
      }
      // Close the country changing pop-up
      $("#confirmCountryChange").modal('hide');
      
      // Enable closing the window if it is allowed
      if (data.enableClosing) {
        $(".complete-registration-form .close").show();
      }
      
      // Show resend welcome email message/button
//      if (data.emailVerificationSent) {
//        $(".verify-email").show();
//      }
      
      // Show next tab
      var currentTabPane = $form.closest('.tab-pane');
      if (currentTabPane) {
        var currentTab = $("a[aria-controls='" + currentTabPane.attr('id') + "']")
        if (currentTab) {
          currentTab.addClass("submitted");
          currentTab.closest('li').addClass("submitted");
        }
        //var nextTab = currentTab.closest('li').next('li').find('a');
        
        var nextTabId =  currentTabPane.data('next-tab');
        if (nextTabId) {
          var nextTab = $("a[aria-controls='" + nextTabId + "']");
          if (nextTab && nextTab.length > 0) {
            nextTab.prop("disabled", false);
            nextTab.closest('li').removeClass("disabled");
            nextTab.attr("data-toggle", "tab");
            nextTab.tab('show');
            
            centerModal();
            $("#" + nextTabId + " .autofocus").trigger("focus");
            
            ga('send', 'event', 'CompleteRegistrationForm',nextTabId);
          }
        }
      }
      
      if (data.consentTermsAndConditions !== undefined) {
        $("body.whitepapers input[name='consent-terms']").iCheck(data.consentTermsAndConditions? "check" : "uncheck");
      }
      if (data.consentDaily !== undefined) {
        $("body.whitepapers input[name='consent-daily']").iCheck(data.consentDaily? "check" : "uncheck");
      }
      if (data.consentWeekly !== undefined) {
        $("body.whitepapers input[name='consent-wmy']").iCheck(data.consentWeekly? "check" : "uncheck");
      }
      if (data.consentSpecialOffers !== undefined) {
        $("body.whitepapers input[name='consent-specialoffers']").iCheck(data.consentSpecialOffers? "check" : "uncheck");
      }
      if (data.consentPersonalizeArticles !== undefined) {
        $("body.whitepapers input[name='consent-personalize']").iCheck(data.consentPersonalizeArticles? "check" : "uncheck");
      }
      if (data.doNotSell !== undefined) {
          $("body.whitepapers input[name='consent-do-not-sell']").iCheck(data.doNotSell? "check" : "uncheck");
        }
    }
    else if (data.status == 'validationError')
    {
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          var input = $form.find("[name='" + key + "']");
          var val = data[key];
          input.addClass('input-error');
          input.closest('.form-group').addClass('input-error');
          input.before( '<p class="input-error">' + val + '</p>' );
        }
      }
    }
    else if (data.status == 'countryConsentPopUp')
    {
      var country = data.country;
      $("#confirmCountryChange input[name='country']").val(country);
      $("#confirmCountryChange input[name='newslettersDaily']").iCheck("uncheck");
      $("#confirmCountryChange input[name='newslettersWeekly']").iCheck("uncheck");
      $("#confirmCountryChange input[name='specialOffers']").iCheck("uncheck");
      $("#confirmCountryChange input[name='personalizeArticles']").iCheck("uncheck");
      $("#confirmCountryChange input[name='doNotSell']").iCheck("uncheck");
      $("#confirmCountryChange input[name='termsAndConditions']").iCheck("uncheck");
      $("#confirmCountryChange input[name='newslettersDaily']").trigger("focus");
      $("#confirmCountryChange").modal("show");
    }
    
    if (data.message) {
      validation
          .html($("<p></p>").html(data.message))
          .show();
    }
    
    if (data.redirect) {
      $('form.dirty').removeClass('dirty');
      setTimeout(function() {
        window.location.replace(data.redirect);
      }, 1500);
    } 
    else 
    {
      if (loading) {
        loading.hide();
      }

      if (typeof grecaptcha !== 'undefined') {
        grecaptcha.reset();
      }
      
      if (data.reload) {
        $('form.dirty').removeClass('dirty');
        setTimeout(function() {
          window.location.reload();
        }, 1500);
      }
    }
  }
  //loginForm.ajaxForm(ajaxFormSubmitOptions);
  //registrationForm.ajaxForm(ajaxFormSubmitOptions);
  //forgotPasswordForm.ajaxForm(ajaxFormSubmitOptions);
  $('form.ajax-wizard-form').ajaxForm(ajaxWizardFormSubmitOptions);

  $("#UPC-pouizvcs").on("keydown", function(e){
    if (e.keyCode === 9) {
      $("#UPC-pouizvcs").blur();
      $("#recaptcha_response_field").trigger("focus");
      return false;
    }
  });

//  function loadCaptchaIfNecessary() {
//    if (!window.RecaptchaLoaded
//        && $("#recaptchaPlaceholder").length > 0) {
//      $.getScript("https://www.google.com/recaptcha/api/js/recaptcha_ajax.js", function() {
//        Recaptcha.create(
//            window.RecaptchaPublicKey,
//            "recaptchaPlaceholder",
//            { theme: "clean" });
//        window.RecaptchaLoaded = true;
//      });
//    }
//  }

  function showLoginForm() {
    validation.empty();
    switchingDialogs = true;
    forgotPasswordForm.modal('hide');
    registrationForm.modal('hide');
    fullCompleteRegistrationForm.modal('hide');
    shortCompleteRegistrationForm.modal('hide');
    accountBlockedModal.modal('hide');
    loginForm.modal('show');
    switchingDialogs = false;
  }
  
  showLoginFormFunction = showLoginForm;
  
  function showRegistrationForm() {
    validation.empty();
    switchingDialogs = true;
    forgotPasswordForm.modal('hide');
    loginForm.modal('hide');
    fullCompleteRegistrationForm.modal('hide');
    shortCompleteRegistrationForm.modal('hide');
    accountBlockedModal.modal('hide');
    registrationForm.modal('show');
    switchingDialogs = false;
  }
  
  $('select.country-select').on("change", function(){
    showStateField($(this).val());
  });
  
  function showCompleteRegistrationForm(signupEmail, eu, country, shortVersion, emailVerified, data) 
  {
    if (initializeSelectInputFields !== undefined) {
      initializeSelectInputFields();
    }
    
    $("input[name='signupEmail']").val(signupEmail);
    $("span.signupEmail").html(signupEmail);
    
    if (eu) {
      $("input[name='newslettersDaily']").iCheck('uncheck');
      $("input[name='newslettersWeekly']").iCheck('uncheck');
      $("input[name='specialOffers']").iCheck('uncheck');
      $("input[name='personalizeArticles']").iCheck('uncheck');
      $("input[name='doNotSell']").iCheck('uncheck');
      $("input[name='termsAndConditions']").iCheck('uncheck');
      $(".complete-registration-form .close").hide();
    }
    else {
      $("input[name='newslettersDaily']").iCheck('check');
      $("input[name='newslettersWeekly']").iCheck('check');
      $("input[name='specialOffers']").iCheck('check');
      $("input[name='personalizeArticles']").iCheck('check');
      $("input[name='doNotSell']").iCheck('check');
      $("input[name='termsAndConditions']").iCheck('check');
      $(".complete-registration-form .close").hide();
    }
    
    if (country !== undefined) {
      $("input[name='country']").val(country);
      $(".country-select").val(country);
      showStateField(country);
    }
    
    validation.empty();
    switchingDialogs = true;
    forgotPasswordForm.modal('hide');
    loginForm.modal('hide');
    registrationForm.modal('hide');
    accountBlockedModal.modal('hide');
    
    if (emailVerified) {
      $('.verify-email').hide();
    }
    
    if (data && data.multipleSubscriptions) {
      $("#confirmCountryChange input[name='multipleSubscriptions']").val("true");
      $("#confirmCountryChange input[name='formMode']").val("full");
      
      $("#confirmCountryChange #singleSitePanel").hide();
      $("#confirmCountryChange #acceptTermsPanel").show();
      $("#confirmCountryChange #consentOptions").hide();
      $("#confirmCountryChange #updateAllSitesPanel").tab('show');
      
      $("span.siteList").html(" (" + data.siteList + ")");
      
      if (data.updateAllSitesPanel || data.updateEachSitePanel) {
        if (data.updateAllSitesPanel) {
          $("#updateAllSitesPanel").html(data.updateAllSitesPanel);
        }
        
        if (data.updateEachSitePanel) {
          $("#updateEachSitePanel").html(data.updateEachSitePanel);
        }
        
        $('input.flat').iCheck({
          checkboxClass: 'icheckbox_minimal-blue',
          radioClass: 'iradio_minimal-blue',
          increaseArea: '20%' // optional
        });

        $('.slim-scroll').each(function() {
          var container = $(this).closest('.slim-scroll-container');
          $(this).slimScroll({
            height: container.height() - container.css('margin-top').replace("px", "")
          });
        });
      }
    }
    else {
      $("#confirmCountryChange input[name='multipleSubscriptions']").val("false");
      $("#confirmCountryChange input[name='formMode']").val("full");
      
      $("#confirmCountryChange #singleSitePanel").show();
      $("#confirmCountryChange #acceptTermsPanel").hide();
      $("#confirmCountryChange #consentOptions").hide();
      
      $("span.siteList").html("");
    }
      
    if (shortVersion) {
      shortCompleteRegistrationForm.modal('show');
    }
    else {
      fullCompleteRegistrationForm.modal('show');
    }
    
    if (data) {
      $("input[name='userFirstName']").val(data.userFirstName);
      $("input[name='userLastName']").val(data.userLastName);
      $("input[name='userOrganization']").val(data.userOrganization);
      $("input[name='title']").val(data.title);
      $("select[name='jobLevel']").val(data.jobLevel);
      $("select[name='jobLevel']").trigger('change');
      $("select[name='companySize']").val(data.companySize);
      $("select[name='companySize']").trigger('change');
      $("input[name='userPhone']").val(data.userPhone);
      $("input[name='userAddress']").val(data.userAddress);
      $("input[name='userCity']").val(data.userCity);
      $("input[name='userPostalCode']").val(data.userPostalCode);
      $("input[name='userCountry']").val(data.userCountry);
      $("select[name='userState']").val(data.userState);
      $("select[name='userState']").trigger('change');
      $("select[name='userProvince']").val(data.userState);
      $("select[name='userProvince']").trigger('change');
      $("input[name='userRegion']").val(data.userState);
      $("select[name='industry']").val(data.industry);
      $("select[name='industry']").trigger('change');      
    }
    
    switchingDialogs = false;
  }
  
  showCompleteRegistrationFormFunction = showCompleteRegistrationForm;
  
  function showForgotPasswordForm() {
    validation.empty();
    switchingDialogs = true;
    loginForm.modal('hide');
    fullCompleteRegistrationForm.modal('hide');
    shortCompleteRegistrationForm.modal('hide');
    registrationForm.modal('hide');
    accountBlockedModal.modal('hide');
    forgotPasswordForm.modal('show');
    switchingDialogs = false;
  }
  
  function showAccountBlockedModal() {
    validation.empty();
    switchingDialogs = true;
    loginForm.modal('hide');
    fullCompleteRegistrationForm.modal('hide');
    shortCompleteRegistrationForm.modal('hide');
    registrationForm.modal('hide');
    forgotPasswordForm.modal('hide');
    accountBlockedModal.modal('show');
    switchingDialogs = false;
  }
  
  function showSendEmailModal(email, needsSubscription) {
    switchingDialogs = true;
    $("#newLoginForm").modal('hide');
    $("#newRegistrationForm").modal('hide');
    $("#newForgotPasswordForm").modal('hide');
    $("#fullCompleteRegistrationForm").modal('hide');
    $("#shortCompleteRegistrationForm").modal('hide');
    $("#set-password-confirm").modal('show');
    $("#set-password-email").val(email);
    $("#set-password-email-text").text(email);
    $("#set-password-needs-subscription").val(needsSubscription);
    if(needsSubscription) {
      $('#set-password-terms-and-condition').prop('required',true);
      $(".needsSubscription").show();
    } else {
      $('#set-password-terms-and-condition').prop('required',false);
      $(".needsSubscription").hide();
    }
    switchingDialogs = false;
  }
   
  

  $(".signInButton").on("click", function(e) {
    e.preventDefault();
    showLoginForm();
  });
  
  $(".mobileSignInButton").on("click", function(e) {
    e.preventDefault();
    $('#main-navbar').removeClass('on-top');
    $('.mobile-menu-modal').modal('hide');
    showLoginForm();
  });
  
  $(".forgotPasswordButton").on("click", function(e) {
    e.preventDefault();
    showForgotPasswordForm();
  });

  $(".signUpButton").on("click", function(e) {
    e.preventDefault();
    showRegistrationForm(true, false);
  });
  
  $(".testForgotPasswordButton").on("click", function(e) {
    e.preventDefault();
    forgotPasswordForm(true, false);
  });

  function centerModal() 
  {
    window.scrollTo(0, 0);
    // $(window).scrollTop(0);
    $('body').scrollTop({scrollTop: 0, scrollLeft: 0});

    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) 
        || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/Android/i)) 
    {
      $(".modal-open .modal.in").each(function(index, element) {
        var viewportmeta = document.querySelector('meta[name="viewport"]');
        if (viewportmeta) {
          viewportmeta.setAttribute('content', 'width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0');
          viewportmeta.setAttribute('content', 'width=device-width, initial-scale=1.0');
        }
        
        // var left = (document.getElementById("table-layout").scrollWidth - $(this).get(0).scrollWidth) / 2;
        var left = 0;
        $(this).css({ left: left + 'px' });
        $(this).find('.modal-dialog').css('margin-left', '10px');
      });
    } 
  }
  
  switchingDialogs = false;
  modalWindowCounter = 0;
  
  $('.center-modal').on('shown.bs.modal', function (e) {
    modalWindowCounter++;
    centerModal();
    $(this).find('.autofocus').trigger("focus");
  });
  
  $('.center-modal').on('hidden.bs.modal', function (e) {
    modalWindowCounter--;
    if (modalWindowCounter > 0) {
      $("body").addClass("modal-open");
    }
    else {
      if (!switchingDialogs) {
        var hashIndex = window.location.href.indexOf('#');
        if (hashIndex >= 0) {
          window.location.replace(window.location.href.substr(0, hashIndex));
        }
        else {
          if ($('body.whitepapers').length == 0) {
            $('form.dirty').removeClass('dirty');
            window.location.reload();
          }
        }
      }
    }
  });
  
  // For popUpSubscription.js to show signUpForm
  function showPopUpSignUpForm() {
    showRegistrationForm();
  }
  window.showPopUpSignUpForm = showPopUpSignUpForm;
  
  if (!userLoggedIn) {
    /* If the user is signed in via Twitter or LinkedIn but not signed in
       as an Aggregage user, show complete registration form so we can get an email address. */
    if (isSignedInViaTwitter || isSignedInViaLinkedIn) {
      showCompleteRegistrationForm();
    } else if (showSubscribe) {
      // If the url has "?subscribe=true" then show the sign up form if no user is signed in.
      showRegistrationForm();
    }
  }

  loginForm.find("a.forgotPassword").on("click", function(e) {
    e.preventDefault();
    validation.hide();
    showForgotPasswordForm();
  });
  
  forgotPasswordForm.find("a.rememberPassword").on("click", function(e) {
    e.preventDefault();
    validation.hide();
    showLoginForm();
  });

  loginForm.find("a.noAccount").on("click", function(e) {
    e.preventDefault();
    validation.hide();
    showRegistrationForm();
  });

  forgotPasswordForm.find("a.login").on("click", function(e) {
    e.preventDefault();
    validation.hide();
    showLoginForm();
  });

  registrationForm.find("a.login").on("click", function(e) {
    e.preventDefault();
    validation.hide();
    showLoginForm();
    
  });
  
  fullCompleteRegistrationForm.find('a.forgotPassword').on('click', function(e) {
    e.preventDefault();
    validation.hide();
    showForgotPasswordForm();
    
  });

  /* Clear out honey pot fields in case they were autocompleted. */
  $('#email').val('');
  $('#password').val('');
  $('#confirm-password').val('');

  /* Process the forms via ajax if there are no clientside validation errors.
     Show any validation errors that couldn't be checked on clientside */
  $("#user-forgot-password-form").on("submit", function(e) 
  {
    
    e.preventDefault();
    
    var email = $('#user-current-email').val();
    
    var $this = $(this),
      inputs = $this.find("input, button"),
      submit = inputs.filter("input[type=submit], button[type=submit]"),
      loading = $this.find(".loading-indicator"),
      validation = $this.find(".validation").empty().hide();

    if ($this.valid()) {
        
      var container = $('#recaptchaV2ForgotContainer');
      
      var recatpchaCallback = function()
      {
        $this.submit();
      }
      
      var processRecaptcha = function(token)
      {
          $.ajax({
              type: "POST",
              url: $this.attr("action"),
          data: {"user-current-email": email, "token": token, "version":recaptchaForgotVersion},
              dataType: 'json',
              beforeSend: function() {
                loading.show();
                inputs.attr("disabled", "disabled");
              },
              success: function(data) {
            
            if(data.invalidRecaptcha)
            {
              container.show();
              recaptchaForgotVersion = "V2";
              widgetId1 = grecaptcha.render('recaptchaV2ForgotDIV', {
                'sitekey' : grecaptchav2_sitekey,
                'callback' : recatpchaCallback
              });
            }
            else
            {
              container.hide();
            }
            
                if (data.errors) {
                  $.each(data.errors, function(key, value) {
                    validation.append($("<p></p>").text(value))
                  });
                  validation.show();
                } else if (data.status === "accountBlocked") {
                  showAccountBlockedModal();
                } else if (data.message) {
                  //showLoginForm();
                  //validation = loginForm.find("#user-login-form .validation");
                  validation
                      .html($("<p></p>").html(data.message))
                      .show();
                }
                
                if (data.redirect) {
                  setTimeout(function() {
                    window.location.replace(data.redirect);
                  }, 2500);
                } else {
                  loading.hide();
                  inputs.prop("disabled", false);
                  if (data.reload) {
                    setTimeout(function() {
                      window.location.reload();
                    }, 2500);
                  }
                }
              },
              error: function(data) {
                console.log(data);
              }
            });
      }

      if(recaptchaForgotVersion == "V3")
      {
        grecaptcha.ready(function() {
          grecaptcha.execute(grecaptchav3_sitekey, {action: $this.attr("id").replace(/-/g, "")}).then(function(token) { processRecaptcha(token); });
        });
  }
      else
      {
        var response = grecaptcha.getResponse(
            widgetId1
        );
        if(response != "")
        {
          processRecaptcha(response);
        }
      }
    return false;
    }
  });
  
  $("#user-login-form").on("submit", function(e) {
    var $this = $(this),
    inputs = $this.find("input, button"),
    submit = inputs.filter("input[type=submit], button[type=submit]"),
    loading = $this.find(".loading-indicator"),
    validation = $this.find(".validation").empty().hide();

    if ($this.valid()) {
          $.ajax({
            type: "POST",
            url: $this.attr("action"),
            // Thanks for this jQuery... really useful when you don't serialize the submit element.
            data: $this.serialize() + "&" + submit.attr("name") + "=" + submit.attr("value"),
            dataType: 'json',
            beforeSend: function() {
              loading.show();
              inputs.attr("disabled", "disabled");
            },
            success: function(data) {
              if (data.errors) {
                $.each(data.errors, function(key, value) {
                  validation.append($("<p></p>").text(value))
                });
                validation.show();
              } else if (data.displayLoginForm) {
                $("#lhuser").val(inputs.filter("input[type=email]").val());
                showLoginForm();
                $("#user-login-form").find(".validation")
                  .html($("<p></p>").text(data.message))
                  .show();
              } else if (data.displayFullCompleteRegistrationForm) {
                showCompleteRegistrationForm(data.signupEmail, data.gdprCountry, data.country, false, data.emailVerified, data);
              } else if (data.displayShortCompleteRegistrationForm) {
                showCompleteRegistrationForm(data.signupEmail, data.gdprCountry, data.country, true, data.emailVerified, data);
              } else if (data.status === "accountBlocked") {
                showAccountBlockedModal();
              } else if (data.message) {
                validation
                    .html($("<p></p>").html(data.message))
                    .show();
              } else if (data.expected) {
                showSendEmailModal(data.email, data.needsSubscription);
              }
              
              if (data.redirect) {
                setTimeout(function() {
                  window.location.replace(data.redirect);
                }, 0);
              } else {
                loading.hide();
                inputs.prop("disabled", false);
                if (data.reload) {
                  setTimeout(function() {
                    window.location.reload();
                  }, 0);
                }
              }
            },
            error: function(data) {
              console.log(data);
            }
          });
        
    }
    return false;
    });
  
  
  $(".user-registration-form, #user-login-registration-form").on("submit", function(e) {
    e.preventDefault();

    var recaptchaVersion = "V2";
    
    var $this = $(this),
        inputs = $this.find("input, button"),
        submit = inputs.filter("input[type=submit], button[type=submit]"),
        loading = $this.find(".loading-indicator"),
        validation = $this.find(".validation").empty().hide(),
        captchaValidation = $this.find(".captchaValidation").empty().hide();

    var container = $this.find(".recaptchaContainer");
    
    var recaptchaDiv = container.find(".recaptcha");
    
    if(container.is(":hidden"))
    {
      recaptchaVersion = "V3";
    }
    
    if ($this.valid()) {
      
      var recatpchaCallback = function()
      {
        $this.submit();
      }
      
        var actionName = $this.attr("id");
      
      if(actionName == null || actionName == "") 
      {
          actionName = "user-registration-form";
        }
        actionName = snakeToCamel(actionName);
      
      var processRecaptcha = function(token)
      {
          $.ajax({
            type: "POST",
            url: $this.attr("action"),
            // Thanks for this jQuery... really useful when you don't serialize the submit element.
          data: $this.serialize() + "&" + submit.attr("name") + "=" + submit.attr("value") + "&token="+token + "&version="+recaptchaVersion,
            dataType: 'json',
            beforeSend: function() {
              loading.show();
              inputs.attr("disabled", "disabled");
            },
            success: function(data) {
            container.show();
            if(data.invalidRecaptcha)
            {
              recaptchaVersion = "V2";
              
              if (widgetId1 != null) 
              {
                grecaptcha.reset(widgetId1);
              }
              else
              {
                widgetId1 = grecaptcha.render(recaptchaDiv.attr('id'), {
                  'sitekey' : grecaptchav2_sitekey,
                  'callback': recatpchaCallback
                });
              }
            }
            else
            {
              container.hide();
            }
            if (data.errors) 
            {
                $.each(data.errors, function(key, value) {
                  validation.append($("<p></p>").text(value))
                });
                validation.show();
            } 
            else if (data.displayLoginForm) 
            {
                $("#lhuser").val(inputs.filter("input[type=email]").val());
                showLoginForm();
                $("#user-login-form").find(".validation")
                  .html($("<p></p>").text(data.message))
                  .show();
            } 
            else if (data.displayFullCompleteRegistrationForm) 
            {
                showCompleteRegistrationForm(data.signupEmail, data.gdprCountry, data.country, false, data.emailVerified, data);
            } 
            else if (data.displayShortCompleteRegistrationForm) 
            {
                showCompleteRegistrationForm(data.signupEmail, data.gdprCountry, data.country, true, data.emailVerified, data);
            } 
            else if (data.status === "accountBlocked") 
            {
                showAccountBlockedModal();
            } 
            else if (data.message) 
            {
                validation
                    .html($("<p></p>").html(data.message))
                    .show();
            } else if (data.expected) {
              showSendEmailModal(data.email, data.needsSubscription);
            }
              
            if (data.redirect) 
            {
                setTimeout(function() {
                  window.location.replace(data.redirect);
              }, 0);
            } 
            else 
            {
                loading.hide();
                inputs.prop("disabled", false);
                if (typeof grecaptcha !== 'undefined') {
                  grecaptcha.reset();
                }
                if (data.reload) {
                  setTimeout(function() {
                    window.location.reload();
                  }, 0);
                }
              }
            },
            error: function(data) {
              console.log(data);
            }
          });
      }

      if(recaptchaVersion == "V3")
      {
        grecaptcha.ready(function() {
          grecaptcha.execute(grecaptchav3_sitekey, {action: actionName}).then(function(token) { processRecaptcha(token); });
        });
      }
      else
      {
        var response = grecaptcha.getResponse(
            widgetId1
        ) 
        if(response == "")
        {
          validation.append($("<p></p>").text("Please check the box"));
          validation.show();
        }
        else
        {
          processRecaptcha(response);
        }
      }
    }
    return false;
  });
  
  $("#set-password-form").on("submit", function(e) {
    e.preventDefault();

    var recaptchaVersion = "V2";
    
    var $this = $(this),
        inputs = $this.find("input, button"),
        submit = inputs.filter("input[type=submit], button[type=submit]"),
        loading = $this.find(".loading-indicator"),
        validation = $this.find(".validation").empty().hide(),
        captchaValidation = $this.find(".captchaValidation").empty().hide();

    var container = $this.find(".recaptchaContainer");
    
    var recaptchaDiv = container.find(".recaptcha");
    
    if(container.is(":hidden")) {
      recaptchaVersion = "V3";
    }
    
    if ($this.valid()) {
      
      var recatpchaCallback = function() {
        $this.submit();
      }
      
      var actionName = $this.attr("id");
      
      if(actionName == null || actionName == "") {
        actionName = "user-registration-form";
      }
      actionName = snakeToCamel(actionName);
      
      var processRecaptcha = function(token) {
          $.ajax({
            type: "POST",
            url: $this.attr("action"),
            // Thanks for this jQuery... really useful when you don't serialize the submit element.
            data: $this.serialize() + "&" + submit.attr("name") + "=" + submit.attr("value") + "&token="+token + "&version="+recaptchaVersion,
            dataType: 'json',
            beforeSend: function() {
              loading.show();
              inputs.attr("disabled", "disabled");
            },
            success: function(data) {
              container.show();
              if(data.invalidRecaptcha)
              {
                recaptchaVersion = "V2";
                
                if (widgetId1 != null) 
                {
                  grecaptcha.reset(widgetId1);
                }
                else
                {
                  widgetId1 = grecaptcha.render(recaptchaDiv.attr('id'), {
                    'sitekey' : grecaptchav2_sitekey,
                    'callback': recatpchaCallback
                  });
                }
              }
              else
              {
                container.hide();
              }
              if (data.errors) 
              {
                  $.each(data.errors, function(key, value) {
                    validation.append($("<p></p>").text(value))
                  });
                  validation.show();
              } else {
                $("#set-password-confirm").modal('hide'); 
              }
            },
            error: function(data) {
              console.log(data);
            }
          });
      }

      if(recaptchaVersion == "V3")
      {
        grecaptcha.ready(function() {
          grecaptcha.execute(grecaptchav3_sitekey, {action: actionName}).then(function(token) { processRecaptcha(token); });
        });
      }
      else
      {
        var response = grecaptcha.getResponse(
            widgetId1
        ) 
        if(response == "")
        {
          validation.append($("<p></p>").text("Please check the box"));
          validation.show();
        }
        else
        {
          processRecaptcha(response);
        }
      }
    }
    return false;
  });
  
  $("#user-login-form").validate({"errorClass":"invalid","meta":"validate"});
  $("#user-forgot-password-form").validate({"errorClass":"invalid","meta":"validate"});
  $(".user-registration-form").validate({"errorClass":"invalid","meta":"validate"});
  if (fullCompleteRegistrationForm != null) {
    $("#user-complete-registration-form").validate({"errorClass":"invalid","meta":"validate"});
  }
  
  //Automatically open login/sign up window
  if(location.hash == "#newLoginForm") {
    $("#newLoginForm").modal();
  }
  //Automatically open sign up window
  if(location.hash == "#newRegistrationForm") {
    $("#newRegistrationForm").modal();
  }
  //Automatically open 5-page version of the complete registration window
  if(location.hash == "#fullCompleteRegistrationForm") {
    $("#fullCompleteRegistrationForm").modal();
  }
  
  if (rCountry) {
    showStateField(rCountry);
  }
});
